<template>
  <div class="house-management">
    <PageHeader title="商品库存" />
    <div class="searchView flex a-center m-tb-10 j-between p-lr-20">
      <div v-if="$minCommon.checkMeau('spkcview', userBottons)" class="flex ">
        <el-popover
          v-model="visible"
          class="OrderDropDown"
          placement="bottom"
          trigger="hover"
          :width="80"
          popper-class="test_pop_view"
        >
          <div class="flex column">
            <div class="p-bottom-10 cursor" @click="selectType(1)">按批号查询</div>
            <div class="cursor" @click="selectType(2)">按物料查询</div>
          </div>
          <div slot="reference" class="ModelBttton-white-30 m-right-10" @click="visible = !visible">
            <div>{{ formSearch.SelectType === 1 ? '按批号查询' : '按物料查询' }}</div>
            <i class="el-icon-caret-bottom" />
          </div>
        </el-popover>
        <el-popover
          v-if="$minCommon.checkMeau('spkcexport', userBottons)"
          v-model="visible1"
          placement="bottom"
          trigger="manual"
          :width="80"
          popper-class="test_pop_view"
        >
          <div class="flex column">
            <div class="p-bottom-10 cursor" @click="exportFile(1)">全部导出</div>
            <div class="cursor" @click="exportFile(0)">按已选导出</div>
          </div>
          <div
            slot="reference"
            class="ModelBttton-white-30 m-right-10"
            @click="visible1 = !visible1"
          >
            <i class="el-icon-upload2 f15 p-right-10" /> 导出
          </div>
        </el-popover>

        <div v-if="$minCommon.checkMeau('spkcview', userBottons)" class="ModelBttton-white-30" @click="() => $refs.FieldManagement.openSetFieldManagement()">
          <i class="el-icon-setting f15 p-right-5" />   字段管理
        </div>
      </div>
      <div v-if="$minCommon.checkMeau('spkcview', userBottons)" class="flex m-left-10 SearchBox_30" style="flex: 1">
        <el-input
          v-model="formSearch.KeyWords"
          size="medium"
          :placeholder="formSearch.SelectType === 1 ? '可通过物料号、品牌名称、物料分类、物料简称、物料DI码、物料注册证号、供应商名称搜索' : '可通过物料号、品牌名称、物料分类、物料简称、物料DI码、物料注册证号、供应商名称搜索' "
          class="rule-input-edit"
        >
          <template slot="prepend">
            <div class="pointer" @click="Adsearch">
              <i class="el-icon-s-operation" />
              <span>高级搜索</span>
            </div>
          </template>
          <div
            slot="append"
            @click="searchGetTableDataAsync"
          >
            搜 索
          </div>
        </el-input>
      </div>
      <el-switch
        v-model="isAllChange"
        active-color="#13ce66"
        inactive-color="#ff4949"
        class="m-left-10"
        active-text="全部"
        inactive-text="默认"
        @change="isaLL"
      />
      <div
        class="ModelBttton-white-30 m-lr-10"
        @click="showSetList"
      >
        <i class="el-icon-setting f15 p-right-10" />修改日志
      </div>
      <!-- //  1 :库存不足  2： 库存积压 3：库存滞销 4： 效期预警 -->
    </div>
    <div class="searchView flex a-center m-tb-10 j-between p-left-10">
      <div v-if="isDevelopment === 'development' || isDevelopment === 'test'" class="flex">
        <div
          v-if="$minCommon.checkMeau('spkcwarning', userBottons)"
          class="ModelBttton-white-30 m-lr-10"
          @click="() => $router.push({name:'ProductWarning'})"
        >
          <i class="el-icon-setting f15 p-right-10" /> 产品预警设置
        </div>
        <el-popover
          v-if="$minCommon.checkMeau('spkcwarning', userBottons)"
          class="OrderDropDown"
          placement="bottom"
          trigger="hover"
          :width="80"
          popper-class="test_pop_view"
        >
          <div class="flex column">
            <div class=" cursor" @click.stop="() => $router.push({name:'DateWarning',params:{type:1}})">库存不足</div>
            <div class="p-tb-5 cursor" @click.stop="() => $router.push({name:'DateWarning',params:{type:2}})">库存积压</div>
            <div class="cursor" @click.stop="() => $router.push({name:'DateWarning',params:{type:3}})">库存滞销</div>
            <div class="p-tb-5 cursor" @click.stop="() => $router.push({name:'DateWarning',params:{type:4}})">效期预警</div>
          </div>
          <div slot="reference" class="ModelBttton-white-30">
            <div>产品预警</div>
            <i class="el-icon-caret-bottom f15" />
          </div>
        </el-popover>
      </div>
    </div>
    <SearchKeys v-if="isProductCjq" class="m-lr-20" :search-keys="searchKeys" @close="closeTag($event,formSearch,adSearchForm,'Date', 'InStockStartDate','InStockEndDate')" />
    <div class="p-lr-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        height="calc(100vh - 365px)"
        row-key="IDX"
        border
        :fit="true"
        @cell-mouse-enter="rowInter"
        @cell-mouse-leave="rowLeave"
        @select-all="selectAll"
        @select="select"
      >
        <!--         @cell-mouse-enter="rowInter"
        @cell-mouse-leave="rowLeave"-->
        <af-table-column fixed="left" type="selection" width="40" />
        <template v-if="formSearch.SelectType === 1">
          <template v-for="(item,index) in tableListJson.houseManagement1">
            <!-- 税率-->
            <af-table-column
              v-if="item.isChecked && item.prop === 'Tax'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <template v-if="item.isChecked" slot-scope="scope">
                {{ scope.row[item.prop] }}
              </template>
            </af-table-column>
            <!--            效期-->
            <af-table-column
              v-if="item.isChecked && item.prop === 'MaterialValidDate'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <template v-if="item.isChecked" slot-scope="scope">
                {{ $minCommon.setDateFormate(scope.row.MaterialValidDate) }}
              </template>
            </af-table-column>
            <!--      入库时间-->
            <af-table-column
              v-if="item.isChecked && item.prop === 'MaterialInStockDate'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <template v-if="item.isChecked" slot-scope="scope">
                {{ $minCommon.formatDate(new Date(scope.row.MaterialInStockDate), 'yyyy-MM-dd hh:mm') }}
              </template>
            </af-table-column>
            <af-table-column
              v-if="item.isChecked && item.prop !== 'Tax' && item.prop !== 'MaterialValidDate' && item.prop !== 'MaterialInStockDate'"
              :key="index"
              :width="item.prop === 'MaterialName' ? '200px': 'auto'"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <template v-if="item.isChecked" slot-scope="scope">
                {{ scope.row[item.prop] }}
              </template>
            </af-table-column>
          </template>
        </template>
        <template v-if="formSearch.SelectType === 2">
          <template v-for="(item,index) in tableListJson.houseManagement2">
            <el-table-column
              v-if="item.isChecked"
              :key="index"
              :width="item.prop === 'MaterialName' ? '200px': 'auto'"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            />
          </template>
        </template>
        <af-table-column fixed="right" prop="address" align="center" label="操作">
          <template slot-scope="scope">
            <!--             v-model="scope.row.isShowPop"-->
            <el-popover
              placement="left"
              trigger="hover"
              :width="100"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div v-if="$minCommon.checkMeau('spkc_xgphxq', userBottons)" class="OperationButton">
                  <el-link :underline="false" @click="dialog_view_show(scope.row)"><i class="el-icon-view p-right-10" />修改批号/效期</el-link>
                </div>
                <div class="OperationButton">
                  <el-link v-if="$minCommon.checkMeau('spkcviewattr', userBottons)" :underline="false" @click="viewImage(scope.row)"><i class="el-icon-view p-right-10" />查看附件</el-link>
                </div>
                <div class="OperationButton">
                  <el-link v-if="$minCommon.checkMeau('spkcdownloadattr', userBottons)" :underline="false" @click="downImages(scope.row)"><i class="el-icon-download p-right-10" />下载附件</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </af-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <Adsearch :form="formSearch" :ad-search-form="adSearchForm" :show="isShow" @cancel="(e) => isShow = e" @searchAd="searchAd" />
    <!-- <div v-for="(item,index) in MaterialFileList" :key="index"> -->
    <el-image-viewer v-if="showViewer" :url-list="MaterialFileList" :on-close="showViewerClose" />
    <!-- </div> -->
    <!-- 修改日期 end -->
    <el-dialog width="70%" class="DepartmentBox" :visible="dialog_view" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between ">
        <span class="Name">修改批号/效期</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => dialog_view = false" />
      </div>
      <div class="show_info flex a-center j-between p-lr-10 p-tb-5 m-top-10" style="background-color: rgb(241,259,255)">
        <div class="flex"><span style="width: 80px">物料编号：</span><span class="el-1">{{ info_diagle.MaterialInvCode }}</span></div>
        <div class="flex"><span style="width: 80px">物料名称：</span><span class="el-1">{{ info_diagle.MaterialName }}</span></div>
        <div class="flex"><span style="width: 80px">物料规格：</span><span class="el-1">{{ info_diagle.Specification }}</span></div>
        <div class="flex"><span style="width: 80px">物料品牌：</span><span class="el-1">{{ info_diagle.BrandName }}</span></div>
      </div>
      <div class="show_info flex j-between a-center m-top-10  p-lr-10 p-tb-5" style="background-color: rgb(241,259,255)">
        <div class="flex"><span style="width: 80px">原批号：</span><span class="el-1">{{ info_diagle.MaterialLotNum }}</span></div>
        <div class="flex"><span style="width: 80px">原效期：</span><span class="el-1">{{ info_diagle.MaterialValidDate? info_diagle.MaterialValidDate.split(' ')[0] : info_diagle.MaterialValidDate }}</span></div>
        <div class="flex"><span style="width: 85px">原生产日期：</span><span class="el-1">{{ info_diagle.ProductDate }}</span></div>
        <div class="flex"><span style="width: 80px">入库批次：</span><span class="el-1">{{ info_diagle.MaterialLotNo }}</span></div>
      </div>
      <div class="m-top-20" />
      <div class="new_set flex j-between a-center">
        <div class="flex a-center">
          <span style="width: 80px">新批号：</span>
          <el-input v-model="info_diagle_new.LotNum" size="mini" />
        </div>
        <div class="flex a-center">
          <span style="width: 70px"> 新效期：</span>
          <el-date-picker
            v-model="info_diagle_new.ExpiredDate"
            size="mini"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          />
        </div>
        <div class="flex a-center">
          <span style="width: 90px">新生产日期：</span>
          <el-date-picker
            v-model="info_diagle_new.ProductDate"
            size="mini"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </div>
      </div>
      <div class="m-tb-20" />
      <div class="flex a-center">
        <span style="width: 80px">修改理由：</span>
        <el-input v-model="info_diagle_new.Reason" size="mini" />
      </div>
      <div class="foot_info flex j-center a-center m-tb-20">
        <el-button size="mini" @click="() => dialog_view = false">取消</el-button>
        <el-button size="mini" type="primary" @click="submitSet">确认</el-button>
      </div>
      <div class="f14 weight p-bottom-10" style="color:red">此功能只在录错单据时进行特殊调整，调整后会产生记录，记录无法删除</div>
    </el-dialog>
    <!--  showSetListDiagle-->
    <el-dialog class="DepartmentBox" width="80%" :visible="showSetListDiagle" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="dialog_view" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between ">
        <span class="Name">修改日志</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => showSetListDiagle = false" />
      </div>
      <div class="flex m-left-10 SearchBox_30 m-top-20" style="flex: 1">
        <el-input
          v-model="searchProducts.KeyWords"
          size="medium"
          placeholder="可通过物料号、物料名称、品牌名称、新批号、新批次搜索"
          class="rule-input-edit"
        >
          <div
            slot="append"
            @click="GetUpdateMaterialStockLogListByStockID(1)"
          >
            搜 索
          </div>
        </el-input>
      </div>
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        class=" m-tb-20"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="setinfoList"
        tooltip-effect="dark"
        show-header
        row-key="IDX"

        border
        :fit="true"
      >
        <el-table-column align="center" prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column align="center" prop="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column align="center" prop="BrandName" label="品牌" show-overflow-tooltip />
        <el-table-column align="center" prop="Specification" label="规格/型号" show-overflow-tooltip />
        <el-table-column align="center" prop="LotNo" label="入库批次" show-overflow-tooltip />
        <el-table-column align="center" prop="OldLotNum" label="原批号" show-overflow-tooltip />
        <el-table-column align="center" prop="OldExpiredDate" label="原效期" show-overflow-tooltip />
        <el-table-column align="center" prop="OldProductDate" label="原生产日期" show-overflow-tooltip />
        <el-table-column align="center" prop="NewLotNum" label="新批号" show-overflow-tooltip />
        <el-table-column align="center" prop="NewExpiredDate" label="新效期" show-overflow-tooltip />
        <el-table-column align="center" prop="NewProductDate" label="新生产日期" show-overflow-tooltip />
        <el-table-column align="center" prop="Reason" label="修改原因" show-overflow-tooltip />
        <el-table-column align="center" prop="UserNickName" label="操作人" show-overflow-tooltip />
        <el-table-column align="center" prop="WriteTime" label="操作时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container p-tb-20">
        <el-pagination
          :ref="paginationName1"
          :total="tableTotalSize1"
          :pager-count="pagerCount1"
          :page-size="tablePageSize1"
          :background="tableBackground"
          :current-page.sync="currentPage1"
          layout="prev, pager, next, jumper"
          @current-change="GetUpdateMaterialStockLogListByStockID($event)"
        />
      </div>
    </el-dialog>
    <!-- 字段搜索 -->
    <FieldManagement ref="FieldManagement" :select-type="formSearch.SelectType" action-name="QueryStockMaterialList" :list=" formSearch.SelectType ===1 ? tableListJson.houseManagement1 : tableListJson.houseManagement2" :name="formSearch.SelectType ===1 ? 'houseManagement1' :'houseManagement2'" :change-list-table="changeListTable" />
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />
  </div>
</template>
<script>
import Index from '@/minxin/management/index'
export default Index
</script>
<style scoped lang="scss">
@import "@/style/search.scss";
</style>
