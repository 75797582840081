import PageHeader from '@/components/PageHeader'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import root from '@/minxin/root'
const Index = {
  name: 'house-management',
  mixins: [tableMixin, root],
  components: {
    PageHeader,
    Adsearch,
    ElImageViewer
  },
  beforeUpdate() {
    this.$nextTick(() => { // 在数据加载完，重新渲染表格
      this.$refs.multipleTable.doLayout()
    })
  },
  data() {
    return {
      root_name: 'spkc',
      root_msg: '商品库存',
      paginationName1: 'tablePagination1',
      tableTotalSize1: 0,
      tablePageSize1: 20,
      pagerCount1: 5,
      currentPage1: 1,
      showViewer: false,
      isShow: false,
      dialog_view: false,
      showSetListDiagle: false,
      info_diagle: {},
      info_diagle_new: {},
      adSearchForm: [
        { name: '物料编号', value: 'MaterialInvCode' },
        { name: '品牌ID', value: 'BrandName' },
        { name: '物料名称', value: 'MaterialName' },
        { name: '物料类型', value: 'MaterialType' },
        { name: '物料昵称', value: 'MaterialNickName' },
        { name: 'UDI', value: 'MaterialUDICode' },
        { name: '供应商', value: 'MaterialSellCompany' },
        { name: '物料注册证号', value: 'MaterialRegisterNum' },
        { name: '原厂编号', value: 'ManuPartNum' },
        { name: '生产厂家', value: 'ProductFactory' },
        { name: '所在仓库', value: 'StockCode' },
        { name: '入库起始时间段', date: true, value: 'Date', _id: 88 }
      ],
      adSearchForm1: [
        { name: '物料编号', value: 'MaterialInvCode' },
        { name: '品牌ID', value: 'BrandName' },
        { name: '物料名称', value: 'MaterialName' },
        { name: '物料类型', value: 'MaterialType' },
        { name: '物料昵称', value: 'MaterialNickName' },
        { name: 'UDI', value: 'MaterialUDICode' },
        { name: '供应商', value: 'MaterialSellCompany' },
        { name: '物料注册证号', value: 'MaterialRegisterNum' },
        { name: '原厂编号', value: 'ManuPartNum' },
        { name: '生产厂家', value: 'ProductFactory' },
        { name: '所在仓库', value: 'StockCode' },
        { name: '入库起始时间段', date: true, value: 'Date', _id: 88 }
      ],
      isDevelopment: process.env.NODE_ENV,
      visible: false,
      visible1: false,
      isAllChange: false,
      formSearch: {
        SelectType: 1,
        KeyWords: '',
        InStockStartDate: '',
        InStockEndDate: '',
        MaterialInvCode: '',
        BrandName: '',
        MaterialName: '',
        MaterialType: '',
        MaterialNickName: '',
        MaterialSellCompany: '',
        MaterialRegisterNum: '',
        MaterialUDICode: '',
        ManuPartNum: '',
        ProductFactory: '',
        StockCode: '',
        PageIndex: 0,
        PageSize: 15,
        MaterialLotNo: '',
        isAll: 1, // 默认1， 全部2
        Date: []
      },
      searchWords: { KeyWords: '' },
      selects: [],
      searchProducts: { StockID: -1, KeyWords: '', PageSiz: 10, PageIndex: 1 },
      selectsArr: [],
      MaterialFileList: [],
      setinfoList: [],
      rowO: {} // 每一行
    }
  },
  watch: {
    selects(a) {
      this.selectsArr = []
      this.formSearch.MaterialLotNo = ''
      this.formSearch.MaterialInvCode = ''
      if (a.length === 0) {
        this.formSearch.MaterialLotNo = ''
        this.formSearch.MaterialInvCode = ''
        return
      }
      this.selectsArr = a
      // a.map(item => {
      //   this.formSearch.MaterialInvCode += `'${item.MaterialInvCode}'` + ','
      //   this.formSearch.MaterialLotNo += `'${item.MaterialLotNo}'` + ','
      // })
    }
    // ,
    // isShow(a) {
    //   if (!a) {
    //     this.adSearchForm.forEach((element, index) => {
    //       if (element._id === 88) return this.adSearchForm.splice(index, 1)
    //     })
    //   }
    // }
  },
  methods: {
    // 查询全部
    isaLL(e) {
      if (e) {
        // 全部
        this.formSearch.isAll = 2
        this.currentPage = 1
        return this.getTableDataAsync()
      }
      // 默认
      this.formSearch.isAll = 1
      this.currentPage = 1
      return this.getTableDataAsync()
    },
    // 修改日志
    showSetList() {
      this.showSetListDiagle = true
      this.GetUpdateMaterialStockLogListByStockID()
    },
    // 获取修改日志
    GetUpdateMaterialStockLogListByStockID(page) {
      // 把page传过去分页
      this.currentPage1 = 1
      this.searchProducts.PageIndex = page || 1
      // const obj = { StockID: '', KeyWords: '', PageSiz: 10, PageIndex: page || 1 }
      this.$api.GetUpdateMaterialStockLogListByStockID(this.searchProducts).then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.setinfoList = res.Message
      })
    },
    // 修改提交
    submitSet() {
      this.info_diagle_new.StockSellID = this.info_diagle.IDX
      this.$api.EditMaterialStockInfo(this.info_diagle_new).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.dialog_view = !this.dialog_view
        this.getTableDataAsync()
        return this.$message.success('修改成功')
      })
    },
    dialog_view_show(row) {
      console.log(row)
      this.info_diagle = row
      this.info_diagle_new = {}
      this.dialog_view = !this.dialog_view
    },
    async  searchAd() {
      this.formSearch.PageIndex = 1
      this.formSearch.PageSize = this.tablePageSize
      console.log(' this.formSearch', this.formSearch)
      if (this.formSearch.Date.length > 0) {
        this.formSearch.InStockStartDate = this.formSearch.Date[0]
        this.formSearch.InStockEndDate = this.formSearch.Date[1]
      }
      this.setKeysData(this.formSearch, this.adSearchForm) // 展示搜索条件
      const response = await this.$api.QueryStockMaterialList(this.formSearch)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      // this.formSearch.KeyWords = null
      this.isShow = false
    },
    // 进入hover表格行
    rowInter(row) {
      // console.log(row)
      // this.rowO = row
      this.$set(row, 'isShowPop', true)
    },
    rowLeave(row) {
      this.$set(row, 'isShowPop', false)
    },
    Adsearch() {
      if (this.formSearch.SelectType === 1) {
        this.adSearchForm = this.adSearchForm1
      }
      if (this.formSearch.SelectType === 2) {
        this.adSearchForm.forEach((element, index) => {
          if (element._id === 88) return this.adSearchForm.splice(index, 1)
        })
      }
      this.isShow = false
      this.isShow = true
    },
    selectType(type) {
      console.log('type', type)
      this.formSearch.SelectType = type
      this.currentPage = 1
      this.getTableDataAsync()
      this.visible = false
    },
    async getTableDataAsync(page, pageSize) {
      // api/QueryStockMaterialList
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      console.log(' this.formSearch', this.formSearch)
      const response = await this.$api.QueryStockMaterialList(this.formSearch)
      console.log('response', response)
      if (response.RetCode !== '0') return this.$message.error('错误：' + response.Message)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      return response
    },
    // 导出
    exportFile(isAll) {
      console.log(this.formSearch)
      this.visible1 = false
      this.formSearch.MaterialLotNo = ''
      this.formSearch.MaterialInvCode = ''
      this.formSearch.FieldStr = JSON.stringify(this.tableListJson[ this.$refs.FieldManagement.name ])
      if (!isAll && this.selectsArr.length <= 0) return this.$message.warning('请选择你要导出的物料')
      if (isAll) {
        this.formSearch.SelectIDList = []
      }
      if (!isAll) {
        this.formSearch.SelectIDList = []
        this.selectsArr.forEach(item => {
          if (this.formSearch.SelectType === 1) {
            this.formSearch.SelectIDList.push(item.MaterialLotNo)
          }
          if (this.formSearch.SelectType === 2) {
            this.formSearch.SelectIDList.push(item.MaterialInvCode)
          }
        })
      }
      this.$refs.ExportLoading.open()
      // 全部
      this.$api.ExportQueryStockMaterialListByUserField(this.formSearch).then(res => {
        if (res.RetCode === '0') {
          const name = this.$minCommon.getExportName('商品库存')
          this.$refs.ExportLoading.success(res.Message, name)
          return this.$minCommon.downloadFile(res.Message, name)
        }
        return this.$message.error('错误：' + res.RetMsg)
      })
    },
    selectAll(e) {
      console.log(e) // MaterialLotNo
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    // 查看附件
    viewImage(row) {
      console.log(row)
      // row.MaterialFileList
      row.MaterialFileList.map(item => {
        item.URL = this.b2bstaticUrl + item.URL
        this.MaterialFileList.push(item.URL)
      })
      console.log(row.MaterialFileList)
      // this.MaterialFileList = row.MaterialFileList
      this.MaterialFileList.length > 0 ? this.showViewer = true : this.$message.warning('无可查看的附件')
    },
    // 关闭预览
    showViewerClose() {
      this.showViewer = false
    },
    // 下载附件
    downImages(row) {
      if (JSON.parse(row.FileJsonStr).length <= 0) return this.$message.error('无数据可下载')
      this.$api.DownloadFirstCampZip({
        JsonStr: row.FileJsonStr
      }).then(res => {
        this.$minCommon.downloadFile(res.Message, '.zip')
      })
    }
  }
}
export default Index
